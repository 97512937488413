<template>
  <DetailTemplate
    :customClass="'contact-detail detail-page'"
    v-if="getPermission('contact:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0">
          <Status v-if="false" :status="customerActivated"></Status>

          <v-chip
            :key="`active-${dialog_key}`"
            class="ma-2 custom-status font-weight-500 text-uppercase custom-grey-border"
            text-color="white"
            color="green"
            v-if="customerPersonArr?.activated"
            label
          >
            Active
          </v-chip>
          <v-chip
            :key="`inactive-${dialog_key}`"
            class="ma-2 custom-status font-weight-500 text-uppercase custom-grey-border"
            text-color="white lighten-1"
            color="red lighten-1"
            v-else
            label
          >
            In-Active
          </v-chip>
          <v-chip
            color="blue white--text"
            label
            class="ma-2 custom-status font-weight-500 text-uppercase custom-grey-border"
            v-if="customerPersonArr.default == 1"
          >
            <span class="font-size-16 font-weight-500"> Primary </span>
          </v-chip>
        </div>
        <div class="px-2 py-5 text-center">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="image"
          >
          </v-skeleton-loader>
          <template v-else>
            <v-img
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage ? getProfileImage : $defaultProfileImage"
              aspect-ratio="1"
              max-height="140"
              max-width="140"
              class="margin-auto custom-grey-border custom-border-radius-50"
              transition="fade-transition"
            >
            </v-img>
          </template>
        </div>
        <!--  <div class="mb-5 text-center">
          <div class="mb-1">
            Created Date:
            <v-chip small color="blue" text-color="white"
              ><v-icon small>mdi-click</v-icon>
              {{ formatDateTime(customerPersonArr.added_at) }}</v-chip
            >
          </div>
       
        </div> -->
        <div class="">
          <table class="width-100">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Contact Information
              </th>
            </tr>
          </table>
          <table class="width-100">
            <tr v-if="false">
              <td
                class="py-1 font-size-18 font-weight-500 text-capitalize text-nowrap"
                width="100"
              >
                Contact Person #
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <v-skeleton-loader
                  class="custom-skeleton"
                  v-if="pageLoading"
                  type="button"
                >
                </v-skeleton-loader>
                <Barcode v-else :barcode="customerPersonArr?.barcode"></Barcode>
              </td>
            </tr>
            <tr>
              <td
                width="100"
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Contact #
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.barcode">
                  <v-chip
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white p-3"
                    small
                    >{{ customerPersonArr.barcode }}
                  </v-chip></template
                >
                <template v-else
                  ><em class="text-muted">No display name</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                width="100"
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Display Name
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.display_name">{{
                  customerPersonArr.display_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No display name</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                width="100"
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Full Name
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.full_name">{{
                  customerPersonArr.full_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No display name</em></template
                >
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Email
              </td>
              <td valign="top" class="py-1 font-size-18">
                <template v-if="customerPersonArr.primary_email">{{
                  customerPersonArr.primary_email
                }}</template>
                <template v-else><em class="text-muted">No email</em></template>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Mobile
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.primary_phone">{{
                  customerPersonArr.primary_phone
                }}</template>
                <template v-else><em class="text-muted">No phone</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                DID
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.did">{{
                  customerPersonArr.did
                }}</template>
                <template v-else><em class="text-muted">No DID</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Designation
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.designation">{{
                  customerPersonArr.designation
                }}</template>
                <template v-else
                  ><em class="text-muted">No designation</em></template
                >
              </td>
            </tr>
            <!-- <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Department
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr.department">{{
                  customerPersonArr.department
                }}</template>
                <template v-else
                  ><em class="text-muted">No department</em></template
                >
              </td>
            </tr> -->

            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Related Company
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Company #
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr?.customer?.barcode">
                  <router-link
                    :to="
                      getDefaultRoute('company.detail', {
                        params: {
                          id: customerPersonArr?.customer?.id,
                        },
                      })
                    "
                  >
                    <v-chip
                      label
                      color="chip-custom-blue"
                      outlined
                      class="text-white p-3"
                      small
                      >{{ customerPersonArr?.customer?.barcode }}
                    </v-chip>
                  </router-link>
                </template>
                <template v-else><em class="text-muted">No Id</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Company
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr?.customer?.company_name">{{
                  customerPersonArr?.customer?.company_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No company name</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Email
              </td>
              <td valign="top" class="py-1 font-size-18">
                <template v-if="customerPersonArr?.customer?.company_email">{{
                  customerPersonArr?.customer?.company_email
                }}</template>
                <template v-else><em class="text-muted">No email</em></template>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Phone No.
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr?.customer?.company_number">
                  {{ customerPersonArr?.customer?.company_number }}
                </template>
                <em v-else class="text-muted"> no phone number</em>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                fax
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="customerPersonArr?.customer?.company_fax">
                  {{ customerPersonArr?.customer?.company_fax }}
                </template>
                <em v-else class="text-muted"> no fax</em>
              </td>
            </tr>

            <tr v-if="customerPersonArr?.tags?.length">
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Tags
              </th>
            </tr>
            <tr
              v-if="
                customerPersonArr?.tags && customerPersonArr?.tags.length > 0
              "
            >
              <td class="font-size-18 py-1" colspan="2">
                <!-- <v-chip small class="mr-2" text-color="white" color="red"
                  >Testing</v-chip
                > -->
                <template
                  v-if="
                    customerPersonArr.tags && customerPersonArr.tags.length > 0
                  "
                >
                  <v-chip
                    small
                    v-for="(row, index) in customerPersonArr.tags"
                    :key="index"
                    class="mr-2"
                    text-color="white"
                    :color="row.color"
                    >{{ row.text }}</v-chip
                  >
                </template>
                <em v-else class="text-muted"> no tags</em>
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Description
              </th>
            </tr>
            <tr>
              <td colspan="2">
                <template v-if="customerPersonArr?.description">{{
                  customerPersonArr?.description
                }}</template>
                <template v-else
                  ><em class="text-muted">No description</em></template
                >
              </td>
            </tr>
          </table>
          <ContactDetail
            v-if="false"
            :pageLoading="pageLoading"
            :supplier="contactArr"
          ></ContactDetail>
          <template v-if="getPermission('contact:update')">
            <span key="0" class="mt-2 d-block text-center">
              <v-skeleton-loader
                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                v-if="pageLoading"
                type="button"
                height="28"
                width="200"
              >
              </v-skeleton-loader>
              <v-btn
                v-else
                class="custom-bold-button white--text"
                color="cyan"
                small
                depressed
                v-on:click.native.stop="detailDialog = true"
              >
                <v-icon dark left>mdi-pencil</v-icon>Update Contact Details
              </v-btn>
            </span>
          </template>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="28"
        type="text"
      >
      </v-skeleton-loader>
      <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ customerPersonArr.display_name }}
      </h1>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('contact:update')">
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              depressed
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in supplierMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
        depressed
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-tabs
          v-model="contactTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent sticky-tab-0"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="contactTab">
          <v-tab-item v-if="getPermission('project:view')" :value="'project'">
            <Project
              :detail="customerPersonArr"
              :type-id="customerPersonArr?.id"
              type="property_contact_person"
            ></Project>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('project:view')"
            :value="'opportunity'"
          >
            <Opportunity
              :detail="customerPersonArr"
              :type-id="customerPersonArr?.id"
              type="property_contact_person"
            ></Opportunity>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="customer_person"
              :type_id="customerPersonArr.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
    <template v-slot:footer>
      <Dialog
        v-if="detailDialog"
        :commonDialog="detailDialog"
        :dialogWidth="1200"
      >
        <template v-slot:title>Update Contact Details</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('contact:update'),
            }"
            ref="supplierDetailForm"
            v-model.trim="detailForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('supplierDetailForm')"
          >
            <DialogContactDetails
              :contact="customerPersonArr"
              :isDialog="true"
              :options="options"
              v-on:saveContactDetail="updateContactDetail"
            >
            </DialogContactDetails>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('contact:update')">
            <v-btn
              depressed
              :disabled="!detailForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('supplierDetailForm')"
              >Update
            </v-btn>
          </template>
          <v-btn
            depressed
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PUT,
  CLEAR_ERROR,
  GET,
  PATCH,
} from "@/core/services/store/request.module";
import ContactDetail from "@/view/pages/contact/detail/overview/Contact-Detail.vue";
// import ContactPerson from "@/view/pages/contact/detail/overview/Contact-Person.vue";
// import BillingAddress from "@/view/pages/contact/detail/overview/Billing-Address.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { GET_OPTION } from "@/core/services/store/customer.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import DialogContactDetails from "@/view/pages/contact/create-or-update/Contact-Details.vue";
// import DialogContactPersons from "@/view/pages/contact/create-or-update/Contact-Persons.vue";
// import DialogBillingAddress from "@/view/pages/contact/create-or-update/Billing-Address-New.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import SupplierMixin from "@/core/plugins/supplier-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";

import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import PatchSupplierMixin from "@/core/lib/supplier/patch.supplier.mixin";
import Status from "@/view/pages/partials/Status.vue";
// import CustomFormDetail from "@/view/pages/custom-field-v2/CustomFormDetail.vue";
import Opportunity from "@/view/pages/partials/Detail/Opportunity";
import Project from "@/view/pages/partials/Internal-Project.vue";
import { SET_ACTIVE_FIELD_DATA } from "@/core/services/store/custom.fields.module.js";
export default {
  mixins: [
    CommonMixin,
    ValidationMixin,
    SupplierMixin,
    CustomerMixin,
    PatchSupplierMixin,
  ],

  name: "contact-detail",
  title: "contact",
  data() {
    return {
      contactTab: "contact",
      customer: 0,
      supplier: {},
      serviceFormDialog: false,
      pageLoading: true,
      deleteDialog: false,
      contactArr: {},

      tabs: [
        // {
        //   title: "Overview",
        //   icon: "mdi-phone",
        //   key: "contact",
        //   disabled: false,
        // },
        {
          title: "Project",
          icon: "mdi-credit-card",
          key: "project",
          disabled: false,
        },
        {
          title: "Opportunity",
          icon: "mdi-credit-card",
          key: "opportunity",
          disabled: false,
        },
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      supplierMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
      //contactTab: null,
      panel: [0, 1],
      personSelectedRows: [],
      propertySelectedRows: [],
      propertyAddress: {},
      billingAddress: {},
      contactDetail: {},
      options: {},
      customerPersonArr: {},
      contactPerson: [],
      notification: {},
      detailForm: true,
      detailDialog: false,
      formLoading: false,
      personId: 0,
      personForm: true,
      personDialog: false,
      propertyId: 0,
      propertyForm: true,
      propertyDialog: false,
      billingForm: true,
      billingDialog: false,
      notificationForm: true,
      notificationDialog: false,
      dialog_key: Number(new Date()),
    };
  },
  components: {
    ContactDetail,
    // ContactPerson,
    // BillingAddress,
    Dialog,
    DialogContactDetails,
    // DialogContactPersons,
    // DialogBillingAddress,
    DetailTemplate,
    Status,
    InternalHistoryDetail,
    // CustomFormDetail,
    Opportunity,
    Project,
  },
  watch: {
    panel() {
      this.masterDialogClose();
    },
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "supplier/options" })
        .then(({ data }) => {
          _this.options = data.options;
          _this.options.all_cms_categories = data.all_cms_categories;
        })
        .catch((error) => {
          _this.logError(error);
        });
      /*.finally(() => {
          _this.$refs.supplierForm.validate();
        });*/
    },
    activeInactive(data) {
      const _this = this;

      if (_this?.customerPersonArr?.customer?.activated == 0) {
        return false;
      }

      if (_this.customerPersonArr?.default == 1) {
        ErrorEventBus.$emit(
          "update:error",
          "The Primary Contact Person can’t be In-Active."
        );
        return false;
      }
      let status = 0;
      if (data == "active") {
        status = 1;
      }
      // Dispatch the action to update the contact activation status
      _this.$store
        .dispatch(PATCH, {
          url: `single-person/${status}/${_this?.customerPersonArr?.id}`,
          data: {
            id: _this?.customerPersonArr?.id ?? 0,
            status: status,
          },
        })
        .then(() => {
          _this.getSingleCustomerPerson().then((response) => {
            _this.customerPersonArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {});
    },

    updateMoreAction(action) {
      switch (action) {
        case "active":
          this.activeInactive("active");
          break;
        case "inactive":
          this.activeInactive("inactive");
          break;
      }
      /*  _this
        .patchSupplier(_this.supplier, formData)
        .then(() => {
          _this.getSupplier().then((response) => {
            _this.contactArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        }); */
    },
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
    },
    updateContactPerson(param) {
      this.contactPerson = param;
    },
    updatePersonSelectedRows(param) {
      this.bulkRows = { persons: param };
      this.personSelectedRows = param;
    },
    updatePropertySelectedRows(param) {
      this.bulkRows = { property: param };
      this.propertySelectedRows = param;
    },
    updateSupplierPerson({ action }) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
        case "default":
          formData.default = 1;
          break;
      }
      _this
        .patchSupplierContactPerson(
          _this.supplier,
          _this.personSelectedRows,
          formData
        )
        .then(() => {
          _this.getSupplier().then((response) => {
            _this.contactArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateSupplierProperty({ action }) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
      }
      _this
        .patchSupplierProperty(
          _this.supplier,
          _this.propertySelectedRows,
          formData
        )
        .then(() => {
          _this.getSupplier().then((response) => {
            _this.contactArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    async onDetailSubmit(formRef) {
      const _this = this;

      try {
        if (!_this.$refs[formRef].validate()) {
          return false;
        }

        _this.pageLoading = true;
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object();
        let personFormData = new Object();
        // let detailFormData = new Object();
        let addressFormData = new Array();

        if (_this.contactDetail.uen && _this.contactDetail.uen.length < 3) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("The uen must be between 3 and 50 characters")
          );
          _this.formLoading = false;
          _this.pageLoading = false;
          return false;
        }
        if (_this.lodash.isEmpty(_this.contactDetail) === false) {
          for (let key in _this.contactDetail) {
            formData[key] = _this.contactDetail[key];
          }

          if (_this.lodash.isEmpty(formData.profile_logo) === false) {
            // formData.profile_logo = formData.profile_logo.id;
          }
        }

        // if (_this.lodash.isEmpty(_this.contactDetail) === false) {
        //   if (
        //     Object.prototype.hasOwnProperty.call(
        //       _this.contactDetail,
        //       "details"
        //     ) &&
        //     _this.lodash.isEmpty(_this.contactDetail.details) === false
        //   ) {
        //     detailFormData = _this.contactDetail.details;
        //   }
        // }
        if (_this.contactDetail?.category?.color) {
          formData.category_color = _this.contactDetail?.category?.color
            ? _this.contactDetail?.category?.color
            : null;
        }
        if (_this.contactDetail?.category?.text) {
          formData.category = _this.contactDetail?.category?.text
            ? _this.contactDetail?.category?.text
            : null;
        }
        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          personFormData = _this.contactPerson;
        }

        if (_this.lodash.isEmpty(_this.billingAddress) === false) {
          addressFormData.push(_this.billingAddress);
        }

        //formData = _this.lodash.pickBy(formData, _this.lodash.identity);
        addressFormData = _this.lodash.pickBy(
          addressFormData,
          _this.lodash.identity
        );
        formData.default = this.customerPersonArr.default;
        if (_this.lodash.isEmpty(formData) === false) {
          await _this.$store.dispatch(PUT, {
            url:
              "customer/" +
              _this?.contactDetail?.company_id +
              "/contact-person",
            data: { persons: [formData] },
          });
        }

        if (_this.lodash.isEmpty(personFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "supplier/" + _this.supplier + "/contact-person",
            data: { persons: personFormData },
          });
        }

        // if (_this.lodash.isEmpty(detailFormData) === false) {
        //   alert(1)
        //   await _this.$store.dispatch(PUT, {
        //     url: "supplier/" + _this.supplier + "/detail",
        //     data: { details: detailFormData },
        //   });
        // }

        if (_this.lodash.isEmpty(addressFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "address/" + _this.supplier,
            data: { address: addressFormData, type: "supplier" },
          });
        }

        _this
          .getSingleCustomerPerson()
          .then((response) => {
            _this.customerPersonArr = response;
            _this.masterDialogClose();
            if (Array.isArray(this.contactArr.custom_fields)) {
              this.$store.commit(
                SET_ACTIVE_FIELD_DATA,
                this.contactArr.custom_fields
              );
            }
          })
          .finally(() => {
            _this.formLoading = false;
            _this.pageLoading = false;
          });
      } catch (error) {
        _this.formLoading = false;
        _this.pageLoading = false;
        /*  ErrorEventBus.$emit("update:error", InitializeError(error)); */
      }
    },
    showUpdateDialog(param) {
      this.personId = param;
      this.personDialog = true;
    },
    masterDialogClose() {
      this.$root.$emit("clearSelection", true);
      this.personSelectedRows = [];
      this.propertySelectedRows = [];
      this.propertyAddress = {};
      this.billingAddress = {};
      this.contactDetail = {};
      this.contactPerson = [];
      this.notification = {};
      this.detailForm = true;
      this.detailDialog = false;
      this.formLoading = false;
      this.personId = 0;
      this.personForm = true;
      this.personDialog = false;
      this.propertyId = 0;
      this.propertyForm = true;
      this.propertyDialog = false;
      this.billingForm = true;
      this.billingDialog = false;
      this.notificationForm = true;
      this.notificationDialog = false;
      this.deleteDialog = false;
    },
  },
  mounted() {
    const _this = this;
    _this.checkTabPermission();
    _this;
    _this
      .getSingleCustomerPerson()
      .then((response) => {
        _this.customerPersonArr = response;

        this.$store.dispatch(GET_OPTION);
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Contact", route: "contact" },
          { title: "Detail" },
          { title: response.barcode },
        ]);

        // if (_this.customerArr && _this.customerArr.persons) {
        //   this.$store.dispatch(SET_CC_PERSONS, _this.customerArr.persons);
        // }
        // if (Array.isArray(this.customerArr.custom_fields)) {
        //   this.$store.commit(
        //     SET_ACTIVE_FIELD_DATA,
        //     this.customerArr.custom_fields
        //   );
        // }
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });

    // _this
    //   .getSupplier()
    //   .then((response) => {
    //     _this.contactArr = response;
    //    /*  _this.getOptions(); */
    //     _this.$store.dispatch(SET_BREADCRUMB, [
    //       { title: "Supplier", route: "supplier" },
    //       { title: "Detail" },
    //       { title: response.barcode },
    //     ]);
    //     if (Array.isArray(this.contactArr.custom_fields)) {
    //       this.$store.commit(
    //         SET_ACTIVE_FIELD_DATA,
    //         this.contactArr.custom_fields
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     _this.logError(error);
    //      _this.$router.go(-1);
    //   })
    //   .finally(() => {
    //     _this.pageLoading = false;
    //   });
  },
  created() {
    const _this = this;
    _this.customer = _this.$route.params.id;
    if (!_this.customer || _this.customer <= 0) {
      /*  _this.$router.go(-1); */
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.contactTab = _this.$route.query.tab;
    }
  },
  computed: {
    customerActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.customerPersonArr) === false) {
        return _this.customerPersonArr.activated;
      }
      return false;
    },
    getProfileImage() {
      if (!this.customerPersonArr.profile_logo) {
        return null;
      }
      return this.customerPersonArr.profile_logo.file.url;
    },
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.contactArr) === false) {
        if (this.contactArr.display_name) {
          result.push(this.contactArr.display_name);
        }
        // if (this.contactArr.barcode) {
        //   result.push(this.contactArr.barcode);
        // }
      }
      return result.join(" • ");
    },
    // contactTab: {
    //   set(val) {
    //     let query = { ...this.$route.query };
    //     query.tab = val;
    //     if (val != this.contactTab) {
    //       this.$router.push({ query });
    //     }
    //   },
    //   get() {
    //     return this.$route.query.tab || "supplier";
    //   },
    // },
  },
};
</script>
